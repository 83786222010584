<template>
  <!-- 會員條碼popup -->
  <section id="popBarcode" class="pop barcode" @click.prevent="closePopup">
    <div class="popBody" @click.stop>
      <a class="close" @click.prevent="closePopup"
        ><font-awesome-icon
          icon="fa-solid fa-xmark"
          size="lg"
        ></font-awesome-icon
      ></a>
      <!-- <span class="title">會員條碼</span> -->
      <div class="pages">
        <a
          :class="['page', { active: tab === 'member' }]"
          @click.prevent="tab = 'member'"
        >
          <span>會員條碼</span>
        </a>
        <a
          :class="['page', { active: tab === 'carrier' }]"
          @click.prevent="tab = 'carrier'"
        >
          <span>載具條碼</span>
        </a>
      </div>
      <div v-if="tab === 'member'" class="code">
        <!-- ********** v會員資料v ********** -->
        <div>
          <img v-if="userImageUrl" :src="userImageUrl" />
          <span>{{ userDisplayedName }}</span>
        </div>
        <!-- ********** vBarcode/QRcode二擇一v ********** -->
        <QrcodeVue
          v-if="codeType === 'qrcode'"
          :value="memberCode"
          size="250"
          level="H"
        />
        <barcode v-else-if="!memberCode" value="000000000000000000000" format="code128" width="2" />
        <barcode v-else :value="memberCode" format="code128" width="2">
          {{ memberCode }}
        </barcode>
      </div>
      <div v-else class="code">
        <barcode v-if="userCardNo" :value="userCardNo" format="code128" width="2">
          {{ userCardNo }}
        </barcode>
        <!-- ********** v代碼v ********** -->
        <!-- <span>{{ userCardNo }}</span> -->
        <div v-else>
          <span>尚未綁定載具，</span>
          <a class="third fXL" @click.prevent="$router.push('/carrier/edit')">立即前往綁定</a>
        </div>
      </div>
      <div v-show="tab === 'member'" class="action">
        <div class="left">
          <a
            :class="{ active: codeType === 'barcode' }"
            @click.prevent="codeType = 'barcode'"
          >
            <font-awesome-icon
              icon="fa-solid fa-barcode"
              size="xl"
            ></font-awesome-icon>
          </a>
          <a
            :class="{ active: codeType === 'qrcode' }"
            @click.prevent="codeType = 'qrcode'"
          >
            <font-awesome-icon
              icon="fa-solid fa-qrcode"
              size="xl"
            ></font-awesome-icon>
          </a>
        </div>
        <div class="right">
          <span>{{ displayTimerToMinutes() }} 更新</span>
          <a @click.prevent="refreshMemberCode()"
            ><font-awesome-icon
              icon="fa-solid fa-arrows-rotate"
              size="lg"
            ></font-awesome-icon
          ></a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import VueBarcode from 'vue-barcode';
import QrcodeVue from 'qrcode.vue';

export default {
  name: 'BarcodePopup',
  components: {
    barcode: VueBarcode,
    QrcodeVue,
  },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      memberCode: '',
      intervalId: '',
      defaultTimerSec: 300,
      timer: 300,
      codeType: 'qrcode',
      tab: 'member',
    };
  },
  mounted() {
    popBarcode.style.display = 'flex';
    this.refreshMemberCode();
    document.body.style.overflow = "hidden";
  },
  beforeDestroy() {
    this.clearTimer();
    document.body.style.overflow = "scroll";
  },
  computed: {
    ...mapState(['user', 'userInfo']),
    userDisplayedName() {
      return this.userInfo.name;
    },
    // userIdNo() {
    //   return this.user?.user?._json
    //     ? this.hideIdentityNumber(this.user.user._json.idno)
    //     : '';
    // },
    userImageUrl() {
      return this.userInfo ? this.userInfo.picture : '';
    },
    userCardNo() {
      return this.userInfo?.cardNo ? this.userInfo?.cardNo.trim() : null;
    },
  },
  methods: {
    hideIdentityNumber(str) {
      let pattern = /(^[a-zA-Z]{1})(\d{7})(\d{2})/g;
      return str.replace(pattern, function (match, start, middle, tail) {
        return start + '*'.repeat(middle.length) + tail;
      });
    },
    displayTimerToMinutes() {
      let mins = Math.floor(this.timer / 60);
      let secs = (this.timer % 60).toString().padStart(2, '00');
      return `${mins}:${secs}`;
    },
    getMemberCode() {
      let config = {
        url: `${this.apiHost}/identities/v1/merchants/${this.merchantId}/members/${this.user.userId}/one-time-keys/pay`,
        method: 'POST',
      };
      return this.$http(config);
    },
    clearTimer() {
      clearInterval(this.intervalId);
      this.timer = this.defaultTimerSec;
      this.memberCode = '';
      this.intervalId = '';
    },
    periodRefreshMemberCode() {
      this.clearTimer();
      this.intervalId = setInterval(() => {
        this.timer--;
        if (this.timer <= 0) {
          this.refreshMemberCode();
        }
      }, 1000);
    },
    refreshMemberCode() {
      this.periodRefreshMemberCode();
      this.getMemberCode()
        .then((memberCodeRes) => {
          this.memberCode = memberCodeRes.data.oneTimeKey;
        })
        .catch((memberCodeErr) => {
          console.log('memberCodeErr: ', memberCodeErr);
        });
    },
    closePopup() {
      this.$emit('closePopup');
    },
  },
};
</script>

<style scoped src="@/assets/css/payment.css"></style>